<template>
<div>
    <v-container fluid class="pa-0 parentDiv">
        <v-img class="bannerProducto" :src="require('@/assets/img/assists/'+banner+'.png')" />
        <RouterLink to="/">
            <v-img class="backHome" max-width="40" :src="require('@/assets/img/btnHome.png')" />
        </RouterLink>
    </v-container>
    <v-container mt-10></v-container>
    <v-container>
        <v-img max-width="350" class="mx-auto" :src="require('@/assets/img/logos/Assits.png')" />
    </v-container>
    <v-container>
        <p class="naranja font-19 text-center">
            La mejor protección para tu hogar y tu familia
        </p>
        <p class="azul font-19 text-center">
            Te brindamos orientación vía telefónica en caso de que necesites asesoría legal,
            apoyo escolar, enlace con más servicios y en casos de emergencia.
        </p>
        <v-container mt4></v-container>
        <v-layout wrap>
            <v-flex xs12 md3 class="mx-auto">
                <v-layout wrap>
                    <v-flex xs4 md3>
                        <v-img class="float-right" width="40" :src="require('@/assets/img/assists/phone.png')" />
                    </v-flex>
                    <v-flex xs8 md9 class="align-self-center">
                        <span class="naranja text-left ml-5">
                            Mi linea de ayuda
                        </span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-container mt4></v-container>
        <v-layout wrap>
            <v-flex xs12 md5 class="mx-auto">
                <v-layout wrap>
                    <v-flex xs12 md6>
                        <ul class="ml-10">
                            <li class="azul font-15">Línea legal</li>
                            <li class="azul font-15">Línea escolar</li>
                            <li class="azul font-15">Enlace para emergencia</li>
                        </ul>
                    </v-flex>
                    <v-flex xs12 md6>
                        <ul class="ml-10">
                            <li class="azul font-15">Electricista</li>
                            <li class="azul font-15">Cerrajero</li>
                            <li class="azul font-15">Vidriero</li>
                            <li class="azul font-15">Plomero</li>
                        </ul>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
    <Footer programa="Assist" />
</div>
</template>

<script>
import Footer from "@/components/FooterPrograma.vue";

export default {
    name: "Informacion",
    components: {
        Footer
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerAssistMovil';
                case 'sm':
                    return 'BannerAssistMovil';
                case 'md':
                    return 'BannerAssist';
                case 'lg':
                    return 'BannerAssist';
                case 'xl':
                    return 'BannerAssist';
            }
        }
    }
}
</script>

<style>
ul {
    list-style: none;
    /* Quitamos los marcadores */
    padding: 0;
    /* Quitamos el padding por defecto de la lista */
    margin-left: 10px;
    /* Separamos la lista de la izquierda */
}

li::before {
    /* Añadimos contenido antes de cada elemento de la lista */
    content: "\2022";
    /* Insertamos el marcador */
    padding-right: 8px;
    /* Establecemos el espacio entre las viñetas y el list item */
    color: red;
    /* Coloreamos la viñeta */
}
</style>
